
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { ACTION_PERMISSION, ACTION_USER } from '@/store/actions'
import { IUser } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { CLoading },
  directives: { maska },
})
export default class AppPermissionModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  appId!: string

  userIds: (string | undefined)[] = []
  users: IUser[] = []
  selectedUsers: IUser[] = []
  loading = true

  onSelect(user: IUser) {
    const indexToRemove: number = this.users.findIndex((item) => item._id === user._id)
    if (indexToRemove !== -1) {
      this.selectedUsers.push(user)
      this.users.splice(indexToRemove, 1)
    }
    this.updateUserIds()
  }

  onUnSelect(user: IUser) {
    const indexToRemove: number = this.selectedUsers.findIndex((item) => item._id === user._id)
    if (indexToRemove !== -1) {
      this.users.push(user)
      this.selectedUsers.splice(indexToRemove, 1)
    }
    this.updateUserIds()
  }

  async onSubmit() {
    const { dispatch } = this.$store
    let result = null
    result = await dispatch(ACTION_PERMISSION.SYNC_PERMISSION_USERS, {
      app_id: this.appId,
      user_ids: this.userIds,
    })
    if (result) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
    this.visible = false
  }

  @Watch('appId', { immediate: true })
  async updateApi() {
    if (!this.appId) {
      return
    }
    this.selectedUsers = await this.$store.dispatch(ACTION_PERMISSION.LOAD_ITEMS, {
      app_id: this.appId,
    })
    const result = await this.$store.dispatch(ACTION_USER.LOAD_ITEMS)
    this.users = cloneDeep(result)
    this.users = this.users.filter((user) => !this.userIds.includes(user._id) && !user.is_super)

    this.loading = false
  }

  @Watch('$store.getters.users')
  updateUsers() {
    this.users = cloneDeep(this.$store.getters.users)
    this.users = this.users.filter((user) => !this.userIds.includes(user._id))
  }

  @Watch('selectedUsers', { immediate: true })
  updateUserIds() {
    this.userIds = this.selectedUsers.map((user) => user._id)
    this.users = this.users.filter((user) => !this.userIds.includes(user._id))
  }
}
