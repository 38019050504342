
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import mapKeys from 'lodash.mapkeys'
import pickBy from 'lodash.pickby'
import pick from 'lodash.pick'
import { saveAs } from 'file-saver'
import { unparse } from 'papaparse'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:exportStarted', 'update:exportFinished'],
})
export default class JsonCSV extends Vue {
  // Props
  @Prop()
  data!: []

  @Prop()
  fields: [] = []

  @Prop()
  name = 'data.csv'

  @Prop()
  delimiter = ','

  @Prop()
  separatorExcel = false

  @Prop()
  encoding = 'utf-8'

  @Prop()
  advancedOptions = {}

  @Prop()
  labels = {}

  @Prop()
  testing = false

  // unique identifier
  get idName() {
    const now = new Date().getTime()
    return 'export_' + now
  }

  get exportableData() {
    const filteredData = this.cleaningData()
    if (!filteredData.length) {
      return null
    }

    return filteredData
  }

  isType(value, type: string) {
    /* eslint valid-typeof: "error" */
    return typeof value === type
  }

  labelsFunctionGenerator() {
    const labels = this.labels
    if (!this.isType(labels, 'undefined') && !this.isType(labels, 'function') && !this.isType(labels, 'object')) {
      throw new Error('Labels needs to be a function(value,key) or object.')
    }

    if (this.isType(labels, 'function')) {
      return (item) => {
        const _mapKeys = mapKeys(item, labels)
        return _mapKeys
      }
    }

    if (this.isType(labels, 'object')) {
      return (item) => {
        return mapKeys(item, (item, key) => {
          return labels[key] || key
        })
      }
    }

    return (item) => item
  }

  fieldsFunctionGenerator() {
    const fields = this.fields
    if (
      !this.isType(fields, 'undefined') &&
      !this.isType(fields, 'function') &&
      !this.isType(fields, 'object') &&
      !Array.isArray(fields)
    ) {
      throw new Error('Fields needs to be a function(value,key) or array.')
    }

    if (this.isType(fields, 'function') || (this.isType(fields, 'object') && !Array.isArray(fields))) {
      return (item) => {
        return pickBy(item, fields)
      }
    }

    if (Array.isArray(fields)) {
      return (item) => {
        return pick(item, fields)
      }
    }
    return (item) => item
  }

  cleaningData() {
    if (this.isType(this.fields, 'undefined') && this.isType(this.labels, 'undefined')) {
      return this.data
    }

    const labels = this.labelsFunctionGenerator()
    const fields = this.fieldsFunctionGenerator()

    return this.data.map((item) => labels(fields(item)))
  }

  generate() {
    this.$emit('update:exportStarted')
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const dataExport = this.exportableData
    if (!dataExport) {
      console.error('No data to export')
      return
    }

    let csv = unparse(
      dataExport,
      Object.assign(
        {
          delimiter: this.delimiter,
          encoding: this.encoding,
        },
        this.advancedOptions
      )
    )
    if (this.separatorExcel) {
      csv = 'SEP=' + this.delimiter + '\r\n' + csv
    }
    // Add BOM when UTF-8
    if (this.encoding === 'utf-8') {
      csv = '\ufeff' + csv
    }
    this.$emit('update:exportFinished')
    if (!this.testing) {
      const blob = new Blob([csv], {
        type: 'text/csv;charset=' + this.encoding,
      })
      saveAs(blob, this.name)
    }
  }
}
