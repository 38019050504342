import { render } from "./Apps.vue?vue&type=template&id=d2ba09cc&scoped=true"
import script from "./Apps.vue?vue&type=script&lang=ts"
export * from "./Apps.vue?vue&type=script&lang=ts"

import "./Apps.vue?vue&type=style&index=0&id=d2ba09cc&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-d2ba09cc"

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QInput,QIcon,QBtn,QCard,QCardSection,QCardActions});
