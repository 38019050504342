<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>

        <q-btn no-caps flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <AppForm
          v-model="form"
          @update:onCancel="onCancel"
          @update:onSave="onCloseModal"
          @update:onDelete="onCloseModal"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IApp } from '@/utils/types'
import AppForm from './AppForm.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { AppForm },
  directives: { maska },
})
export default class AppFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IApp

  form: IApp = {
    name: '',
    token: '',
    secret: '',
    secret_test: '',
    token_test: '',
    is_test_mode: false,
    auto_reply: {
      is_auto_reply: false,
    },
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.app.add_new_app') : this.$t('label.app.edit_app_information')
  }

  onCloseModal() {
    this.visible = false
    this.$emit('update:closeModal', this.visible)
  }

  async created() {
    this.form = { ...this.form, ...this.data }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
</script>
