import { render } from "./AppPermissionModal.vue?vue&type=template&id=2fd00a99&scoped=true"
import script from "./AppPermissionModal.vue?vue&type=script&lang=ts"
export * from "./AppPermissionModal.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-2fd00a99"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QCardSection,QList,QItem,QItemSection,QItemLabel,QSpace});qInstall(script, 'directives', {Ripple});
