export class CSVHeader {
  public header: {
    [field: string]: string
  } = {}

  constructor(header: { [field: string]: string }) {
    this.header = header
  }

  get fields(): string[] {
    return Object.keys(this.header)
  }

  get labels() {
    return this.header
  }
}
